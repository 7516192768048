// If you want to override variables do it here
@import "variables";
// Import CoreUI styles
@import "~@coreui/coreui/scss/coreui.scss";
// Some temp fixes
@import "fixes";
// If you want to add something do it here
@import "custom";
.message-outer {
    display: inline-block;
    vertical-align: top;
    width: 100%;
    height: 610px;
    overflow: hidden;
}

.message-header {
    padding: 18px 20px;
}

.message-contact-list-outer {
    float: left;
    height: 100%;
    border-right: 1px solid #cbcbcb;
    width: 329px;
}

.contact-list {
    height: calc(100% - 124px);
    overflow-y: auto;
    padding: 0 10px 20px;
    z-index: 1;
    position: relative;
}

.user-list {
    padding: 12px 15px;
    display: inline-block;
    vertical-align: top;
    width: 100%;
    border-radius: 3px;
    &.active {
        background: #F1F2F6;
    }
}

.dropdown-item {
    .contact-detail {
        margin-left: 40px;
    }
}

.heading {
    margin-bottom: 0;
    padding: 8px 0;
}

.contact-image {
    width: 46px;
    height: 46px;
    line-height: 46px;
    float: left;
    .c-avatar {
        width: 46px;
        height: 46px;
    }
    &.sender-img {
        margin-bottom: 27px;
        border-radius: 50%;
        overflow: hidden;
        width: 25px;
        height: 25px;
        line-height: 25px;
        margin-right: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.contact-detail {
    display: flex;
    margin-left: 60px;
    justify-content: space-between;
}

.online-timing {
    display: inline-block;
    line-height: 1.6;
    height: 23px;
    font-size: 11px;
    color: #979AA1;
}

.message-chat-window {
    height: 100%;
    margin-left: 329px;
    position: relative;
    padding-bottom: 100px;
}

.message-typing-outer {
    position: relative;
    height: calc(100% - 50px);
    padding: 40px 0 36px;
    background: #FBFDFC;
}

.message-window {
    height: 100%;
    padding: 0 30px 0 20px; //overflow: auto;
}

.message-by-others {
    display: flex;
    align-content: flex-end;
    align-items: self-end;
    margin-bottom: 15px;
}

.message-by-self {
    text-align: right;
    .message-text {
        margin-left: auto;
    }
}

.message-text {
    background: #F1F2F6;
    padding: 15px 20px;
    border: 1px solid #F1F2F4;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -o-border-radius: 10px;
    -ms-border-radius: 10px;
    font-size: 14px;
    color: #43515A;
    line-height: 1.6;
    margin-bottom: 10px;
    width: 52%;
    display: block;
    text-align: left;
}

.message-text:nth-last-child(2) {
    margin-bottom: 0px;
}

.message-by-others {
    .message-text {
        background: #fff;
    }
}

.message-user-detail {
    display: block;
    background: #CBEEF3;
    padding: 21px 30px;
    color: #0488d9;
    font-size: 18px;
    font-family: OpenSans-Semibold;
    height: 90px;
}

.message-time {
    display: block;
    color: #C7CBCE;
    font-size: 13px;
    line-height: 2;
    padding-right: 5%;
}

.send-message-outer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #E3E7EA;
    padding: 13px 0 11px;
    z-index: 1;
    padding-left: 70px;
    padding-right: 60px;
}

textarea.message-textarea {
    border: none;
    resize: none;
    background: #fff;
    width: 100%;
    padding: 10px 30px;
    height: 45px;
    border-radius: 2px;
    &:focus {
        box-shadow: none;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        -o-box-shadow: none;
        -ms-box-shadow: none;
    }
}

.send-message-outer .btn {
    position: absolute;
    right: 15px;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    top: 50%;
    min-width: 177px;
    font-size: 18px;
    font-family: poppinssemibold;
    padding: 0 25px;
    height: 61px;
}

.contact-list a {
    width: 100%;
}

.message-count {
    height: 18px;
    width: 18px;
    background: #2881F0;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -o-border-radius: 50%;
    -ms-border-radius: 50%;
    font-size: 10px;
    color: #fff;
    font-weight: 900;
    line-height: 18px;
    margin: 4px auto 0 auto;
    text-align: center;
}

.contact-list-control {
    display: none;
}

.online-user {
    position: absolute;
    background: #4CAF50;
    width: 10px;
    height: 10px;
    z-index: 10;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -o-border-radius: 50%;
    -ms-border-radius: 50%;
    bottom: 0;
    right: 0;
}

.contact-list .contact-image {
    overflow: visible;
    position: relative;
}

.header-notify-scroll {
    height: 200px;
    overflow-y: auto;
    width: 350px;
}

.contact-name {
    margin-bottom: 0;
}

.message-text-short {
    max-width: 150px;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    white-space: nowrap;
    font-size: 13px;
    color: #969BA1;
}

.profile-type {
    font-size: 13px;
    color: #979A9F;
}

.chat-search-form {
    background: #F5F6F8;
    position: relative;
    padding: 0 8px;
    span {
        position: absolute;
        color: #969AA5;
        width: 40px;
        height: 100%;
        line-height: 46px;
        text-align: center;
        font-size: 22px;
    }
    .form-control {
        border: none;
        background: transparent;
        padding-left: 40px;
        height: 46px;
        &:focus {
            box-shadow: none;
        }
    }
}

.message-media {
    .contact-name {
        font-size: 15px;
    }
    .profile-type {
        font-size: 11px;
    }
}

.message-action-dropdown {
    .dropdown-toggle {
        width: 29px;
        height: 29px;
        padding: 0;
        background: #F1F2F6;
        line-height: 15px;
        &::after {
            content: none;
        }
    }
}

.get-emojis {
    position: absolute;
    left: 21px;
    top: 50%;
    margin-top: -13px;
}

.send-message {
    position: absolute;
    border: none;
    background: none;
    top: 50%;
    margin-top: -11px;
    right: 15px;
    padding: 0;
}

.current-status {
    padding: 10px 0;
    font-size: 12px;
    text-align: center;
    color: #BABFC3;
}

.no-message-window {
    display: flex;
    align-items: center;
    text-align: center;
    background: #FBFDFC;
    padding-bottom: 0;
}

.no-msg-img {
    margin: 0 auto;
    max-width: 340px;
    color: #D0D3D4;
    .heading {
        margin: 20px 0 10px;
        color: #99A0AA;
    }
}

.notification-text {
    width: 250px;
}

.send-message-outer aside.emoji-picker-react {
    position: absolute;
    bottom: 95%;
    left: 10px;
    padding-bottom: 10px;
}

.action-buttons {
    width: 50px;
}

.chat-input {
    position: absolute;
    width: 100%;
    right: 0;
    text-align: left;
    padding-right: 60px;
    min-width: 350px;
    top: 5px;
}

.close-icon {
    position: absolute;
    right: 70px;
    top: 6px;
    cursor: pointer;
}

td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.cursor-pointer {
    cursor: pointer;
}

.bordered-box {
	border: 1px solid #d8dbe0;
  border-radius: 0.25rem;
  padding: 10px;
  min-height: 35px;
  height: 100%;
  .nav-tabs {
    .nav-link {
      padding: 0;
      border-bottom: 3px solid transparent;
      margin-right: 10px;
      font-size: 12px;
      color: #768192;
      &.active {
        color: #1877F2;
        background-color: transparent;
        border: none;
        border-bottom: 3px solid #1877F2;
      }
    }
    .nav-item.show .nav-link {
      color: #768192;
      background-color: transparent;
      border-bottom: 2px solid #1877F2;
    }
  }
}
.border-box-title {
	color: #1877F2;
  margin: 0 0 3px;
  font-size: 13px;
}
.delete-image-new {
	display: flex;
	flex-wrap: wrap;
}
.uploaded-img {
	margin-right: 10px;
	border-radius: 8px;
	overflow: hidden;
	position: relative;
  width: calc(25% - 10px);
  margin-bottom: 10px;
  max-width: 150px;
  height: 100%;
    aspect-ratio: 1;
  &:last-child {
    margin-right: 0;
  }
  img {
    border-radius: 8px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}
.post-proofs{
  .uploaded-img {
    height: 90px;
    width: 90px;
  }
}
.draft-images {
  overflow-y: scroll;
  height: 150px;
  .uploaded-img {
    margin-right: 10px;
    height: 64px;
    width: 64px;
    &:nth-child(3n) {
      margin-right: 10px;
    }
    &:nth-child(4n) {
      margin-right: 0;
    }
  }
}
.message-chat-header {
	display: flex;
	padding: 7px 25px;
	align-items: center;
}
.chat-imgs {
	display: flex;
	margin-right: 15px;
}
.img-sender, .img-receiver {
	padding: 2px;
  border: 1px solid #AEB3B9;
  background: #fff;
}
.img-receiver {
  margin-left: -13px;
  border-color: #70808D;
}
.chat-between {
	font-size: 13px;
	font-weight: bolder;
  line-height: 1;
  small {
    color: #757C86;
  }
}
.msg-list-body {
	height: calc( 100% - 90px);
	position: relative;
  background: #FBFDFC;
  padding: 20px 25px;
  overflow-y: auto;
  .message-window {
    padding: 0;
  }
  .message-text {
    margin: 0;
    width: 95%;
  }
  .no-msg-img {
    .img-fluid {
      max-width: 100px;
    }
  }
  .message-by-self {
    display: flex;
    align-content: flex-end;
    align-items: self-end;
    margin-bottom: 20px;
    .message-text {
      margin-right: 5%;
    }
  }
  .message-by-others {
    .message-text, .message-time {
      margin-left: 5%;
    }
  }
}
.msg-outer-new {
	height: 100%;
}
.contact-list-new {
  .message-chat-header {
    padding: 15px 15px;
    &.active{
      background: #F1F2F6;
      .img-sender {
        border-color: #3B71B7;
      }
      .img-receiver {
        border-color: #D2BA60;
      }
    }
  }
  .chat-between {
    font-size: 14px;
  }
  .c-avatar {
    width: 48px;
    height: 48px;
  }
  .contact-image {
    width: 96px;
  }
  .online-timing {
    position: relative;
    top: -15px;
  }
}
.message-header-new {
	display: flex;
  justify-content: space-between;
  padding: 9px 20px;
  .profile-type-blue, .profile-type-yellow {
    &::before{
      top: 7px;
    }
  }
}
.profile-type-blue, .profile-type-yellow{
	position: relative;
  padding-left: 10px;
  &::before {
    content: "";
    position: absolute;
    width: 5px;
    height: 5px;
    background: #2775D9;
    left: 0;
    z-index: 1;
    top: 4px;
    border-radius: 50%;
  }
}
.profile-type-yellow{
  &::before {
    background: #E7BD1D;
  }
}
.message-chat-window{
  .message-chat-header {
    padding: 20px 25px;
    border-bottom: #F3F4F6;
  }
}
.message-window-new {
  .message-by-others {
    .contact-image.sender-img {
      margin-right: 20px;
    }
  }
  .message-by-self {
    display: flex;
    align-items: flex-end;
    .contact-image.sender-img {
      margin-left: 20px;
    }
  }
}
.chat-window-new {
	padding-bottom: 0;
}
.message-window-new {
	padding: 0 20px;
}
.c-sidebar .c-sidebar-nav-link.c-active, .c-sidebar .c-active.c-sidebar-nav-dropdown-toggle {
	background: #1A2942;
}
.back-icon{display: none;}


.no-account-outer p {color: #8A98A2;margin: 40px 0;}
.account-detail-head {display: flex;justify-content: space-between;align-items: center;}
.account-detail-head ol {padding-left: 13px;margin-bottom: 0;}
.account-detail-head li {color: #E24949;list-style: disc outside none;}
.card-bank {
	border: 0;
}
.card-bank .card-header {
	background: transparent;
	padding: 15px 30px;
	border: 1px solid #E7EAEE;
	border-radius: 10px 10px 0 0;
	border-bottom: 0;
}
.card-bank .plain-blue-link {font-size: 16px;padding: 2px 0;}
.bank-title {font-size: 20px;font-weight: 600;text-transform: capitalize;}
.card-details-header {display: flex;justify-content: space-between;align-items: center;margin-bottom: 25px;}
.card-details-header .bank-icon-blue {margin-top: -4px;}
.card-details-header .btn-success {background: #24BB88;border-radius: 8px;border-color: #24BB88;}
.card-details-header .card-delete-btn {
	position: relative;
	top: auto;
	right: auto;
	margin-right: 50px;
	padding: 0;
	border: 0;
	background-color: transparent;
}

.card-bank .card-body {
	border: 1px solid #E7EAEE;
	margin-bottom: 20px;
	border-radius: 10px;
	padding: 20px 30px;
}
.card-bank .card-body:nth-child(2){border-radius: 0 0 10px 10px;}
.plain-blue-link {
	background: transparent;
	border: none;
	font-size: 17px;
	font-weight: 600;
	color: #1877F2;
  &.add-new-card-btn {
    font-size: 20px;
    padding: 0;
  }
}
.border-line-right::before, .border-line-left::before {
	content: "";
	position: absolute;
	width: 1px;
	height: 100%;
	background: #D8DBE0;
	right: 0;
	top: 0;
}
.common-icon {
	display: inline-block;
	vertical-align: middle;
	background-repeat: no-repeat;
	background-size: cover;
}
.bank-icon-blue {
	background-image: url("../assets/images/bank.svg");
	width: 29px;
	height: 27px;
}
.card-details-header .bank-icon-blue {
	margin-top: -4px;
}
.card-delete-icon {
	width: 16px;
	height: 19px;
	background-image: url("../assets/images/delete-icon-grey.svg");
	margin-top: -3px;
}
.title-sm {
	font-size: 20px;
	font-weight: 600;
	margin: 20px 0;
}
.upload-profile-img {
	position: relative;
}
.upload-profile-img .custom-file-input {
	position: absolute;
}
.upload-profile-img .form-control {
	padding-right: 75px;
}
.upload-profile-img .btn {
	position: absolute;
	top: 50%;
	right: 0;
	transform: translateY(-50%);
}
.form-field-wrapper {
  margin-bottom: 25px;
  .field-label {
    font-size: 15px;
    font-weight: 600;
  }
  .react-date-picker__wrapper {
    border: none;
    align-items: center;
  }
  .react-date-picker__button {
    background: url("../assets/images/calendar-icon-new.svg") no-repeat;
    background-size: cover;
    width: 17px;
    height: 19px;
    padding: 0;
    svg {
      display: none;
    }
  }
}
.form-field-new .react-date-picker__calendar {
  z-index: 1030;
}
.bank-details .title-xs ,.title-xs {
	color: #8A98A2;
	text-transform: capitalize;
	margin: 15px 0 14px;
	font-size: 17px;
	line-height: 1;
}


.select-field-new .css-t33evk-container {
  margin-top: 0;
  .css-3fklg9-placeholder{
    display: none;
  }
}
.settings-action-div {
  padding: 30px 0 10px;
  text-align: right;
  margin-top: 10px;
  .btn {
    font-size: 15px;
    min-width: 158px;
    height: 35px;
    line-height: 35px;
    padding: 0 20px;
    font-weight: 600;
  }
}
.react-date-picker__calendar {
  z-index: 1030;
}
.icon-new {
  position: relative;
  width: 20px;
  height: 17px;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  line-height: 15px;
  margin-right: 3px;
}
.arrow {
  width: 100%;
  height: 2px;
  background-color: #2819ae;
  display: inline-block;
  vertical-align: middle;
}
.arrow::after, .arrow::before {
  content: '';
  position: absolute;
  width: 41%;
  height: 2px;
  left: 0px;
  background-color: #2819ae;
}
.arrow::after {
  top: 10px;
  transform: rotate(45deg);
}
.arrow::before {
  top: 4px;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
  transform: rotate(-45deg);
}
.nav-tabs .nav-link {
	border: 1px solid transparent;
	border-top-left-radius: 0.25rem;
	border-top-right-radius: 0.25rem;
	padding: 0 10px;
	border-bottom: 2px solid transparent;
}

.bage-color{color:#768192; font-size: 0.875rem; font-weight: normal;}
.color-red{background:#E84258;}
.color-green{background:#83E267;}
.color-orange{background:#FD6839;}
.badge.bg-secondary a{color: #000;}
.color-red a, .color-green a, .color-orange a{color: #fff;}
.c-icon:not(.c-icon-c-s):not(.c-icon-custom-size){
  cursor: pointer;
  margin: 0 5px;
}
.sender, .receiver{width: 5px; height: 5px; border-radius: 50%; background: #2819ae; display: inline-block; margin: 0 8px 0 0;}
.receiver{background: #f3bb04;}
.c-sidebar-brand-full{cursor: pointer;}
.search-box{
    background:#F1F2F6; padding: 15px; position: relative;
    input, input:focus-visible{
      background: transparent;
      padding: 0 20px 0 30px;
      border: none;
      box-shadow: none;
      outline: none;
    }
    .search-btn, .search-btn:focus{
      position: absolute; left: 20px; top:16px;  
      background: url("../assets/images/search-icon.svg") no-repeat;  
      text-indent: -9999px; 
      border: none; box-shadow: none; cursor: pointer;
    }
    .clear-search{
      position: absolute;
      top: 15px;
      right: 20px;
      text-decoration: none;
      color: #313131;
      &:hover{color:#000;}
    }
  }
  .msg-list-body.direct-msg{
    .message-by-self{
      .message-text, .message-time{
        margin-left: auto;
        margin-right: 20px;
        padding-right: 0px;
      }
  }
  .message-by-others{
    .message-text, .message-time{
      margin-left: 0;
    }
  }
   .message-text{
    width: 52%;
    word-break: break-word;
   }
  }
  .msg-list-body .message-by-others .message-text, .msg-list-body .message-by-others .message-time {
      margin-left: 0;
  }
.direct-msg-list{
  width: 75%;
  .contact-name{
    position: relative;
    padding-left: 12px;
    font-size: 13px;
    strong{
      width: 90%;
      word-wrap: break-word;
      display: inline-block;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
  .sender, .receiver{
    position: absolute;
    left: 0;
    top:7px;
  }
}
.package-price {
  color: #24BB88;
  font-family: NeusaNextStd-Medium, sans-serif;
  font-size: 32px;
}
.btn:disabled {
  cursor: not-allowed;
}
/* responsive css */

@media all and (max-width: 500px) {
    .header-notify-scroll {
        width: 300px;
    }
}

@media all and (max-width: 1100px) {
    .chat-input {
        min-width: 320px;
    }
}

@media all and (max-width: 767px) {
    .message-contact-list-outer {
        width: 280px;
    }
    .message-chat-window {
        margin-left: 280px;
    }
}

@media all and (max-width: 640px) {
    .message-outer {
        height: 100%;
        position: relative;
    }
    .message-contact-list-outer {
        width: 100%;
        float: none;
        clear: both;
        position: absolute;
        background: #fff;
        z-index: 2;
        height: 100%;
        left: 0;
        width: 100%;
        top: 0;
        bottom: 0;
        transition: all 0.2s ease-in 0s;
        -webkit-transition: all 0.2s ease-in 0s;
        -moz-transition: all 0.2s ease-in 0s;
        -ms-transition: all 0.2s ease-in 0s;
        -o-transition: all 0.2s ease-in 0s;
        &.close-chat {
          left: -100%;
        }
    }
    .contact-list {
        height: 280px;
    }
    .message-chat-window {
        margin: 0;
        display: block;
        width: 100%;
        height: 440px;
        position: relative;
        z-index: 1;
        &.no-message-window{
          display: flex;
        }
    }
    .message-typing-outer {
      height: calc(100% - 80px);
      padding: 20px 0;
    }
    .back-icon {
      color: blue;
      font-size: 16px;
      margin-bottom: 9px;
      display: inline-block;
      vertical-align: middle;
      cursor: pointer;
    }
    .message-text {
      padding: 10px 15px;
    }
}

